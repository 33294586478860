@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

:root {
  --font--primary: "Satoshi", sans-serif;

  --color-azure: var(--color-azure-500);
  --color-sunray: var(--color-sunray-500);
  --color-gray: var(--color-gray-700);
  --color-opal: var(--color-opal-500);

  --color-eucalyptus: #007AFF;
  --color-opal: #F15A54;
  --color-neutral: #262119;

  /* typography */

  --text-color: var(--color-neutral);
  --text-font: var(--font--primary);

  --subtext-gray-600: #666666;

  --body-font-size-big: 2rem;
  --body-line-height-big: 2.5rem;

  --body-font-size-regular: 1.6rem;
  --body-line-height-regular: 2.4rem;

  --body-font-size-medium: 1.4rem;
  --body-line-height-medium: 2.1rem;

  --body-font-size-small: 1.2rem;
  --body-line-height-small: 1.8rem;

  --body-font-size-tiny: 1rem;
  --body-line-height-tiny: 1.4rem;

  /* layout */

  --text-width: 710px;

  --header-height: 100px;
  --footer-height: 100px;
  --menu-height: 40px;

  /* other */

  --link-color: rgba(0, 0, 0, 0.9);
  --link-color--active: rgba(0, 0, 0, 0.8);
  --link-color--hover: rgba(0, 0, 0, 0.7);

  --white: var(--color-gray-100);

  --padding: 12px;
  --border-color: var(--color-gray-400);
  --border-color-light: var(--color-gray-300);

  /* other */

  --transition: 0.1s;

  /* shadows */

  --box-shadow-4: 0px 4px 6px -1px rgba(38, 33, 25, 0.1), 0px 2px 4px -1px rgba(38, 33, 25, 0.06);
  --box-shadow-5: 0px 0px 3px rgba(38, 33, 25, 0.16), 0px 4px 8px rgba(38, 33, 25, 0.06);
  --box-shadow-6: 0px 20px 24px -4px rgba(24, 45, 68, 0.1), 0px 10px 10px -4px rgba(24, 45, 68, 0.04);

  /* palette */

  --color-sunray-800: #AF9043;
  --color-sunray-700: #C5A24C;
  --color-sunray-600: #D0AB50;
  --color-sunray-500: #DBB454;
  --color-sunray-400: #E2C376;
  --color-sunray-300: #E9D298;
  --color-sunray-200: #F1E1BB;
  --color-sunray-100: #F8F0DD;
  --color-sunray-050: #FCF9F1;

  --color-gray-700: #262119;
  --color-gray-600: #666666;
  --color-gray-500: #B3B3B3;
  --color-gray-400: #DFDEDE;
  --color-gray-300: #F5F5F5;
  --color-gray-200: #FAFAFA;
  --color-gray-100: #FFFFFF;

  --color-orange-100: #FFF3E0;
  --color-orange-500: #FFA425;
  --color-orange-800: #F57902;

  --color-azure-800: #004DA1;
  --color-azure-700: #005EC5;
  --color-azure-600: #006DE4;
  --color-azure-500: #007AFF;
  --color-azure-400: #729DFF;
  --color-azure-300: #A1BAFF;
  --color-azure-200: #C5D4FF;
  --color-azure-100: #E4EAFF;

  --color-opal-800: #983835;
  --color-opal-700: #BA4541;
  --color-opal-600: #D7504B;
  --color-opal-500: #F15A54;
  --color-opal-400: #F38B88;
  --color-opal-300: #F6AFAD;
  --color-opal-200: #F9CDCC;
  --color-opal-100: #FCE7E7;

  --color-orange-800: #F57902;
  --color-orange-700: #FB8901;
  --color-orange-600: #FF9500;
  --color-orange-500: #FFA425;
  --color-orange-400: #FFB54C;
  --color-orange-300: #FFCA7F;
  --color-orange-200: #FFDFB1;
  --color-orange-100: #FFF3E0;

  --color-eucalyptus-800: #318267;
  --color-eucalyptus-700: #3C9F7F;
  --color-eucalyptus-600: #45B892;
  --color-eucalyptus-500: #4ECEA4;
  --color-eucalyptus-400: #85D8B9;
  --color-eucalyptus-300: #ACE2CD;
  --color-eucalyptus-200: #CBECDF;
  --color-eucalyptus-100: #E6F5EF;

  --color-bronze-200: #E3D6CA;
  --color-bronze-300: #D4BEA9;
  --color-bronze-400: #C4A381;
  --color-bronze-500: #B38344;
  --color-bronze-600: #A0753C;
  --color-bronze-700: #8A6534;
}

body,
html {
  font-family: var(--font--primary);
  color: var(--text-color);
  background-color: var(--color-gray-100);
  /* letter-spacing: 0.02em; */
  font-size: 62.5%;
  line-height: 1.4;
  font-weight: 400;
  min-width: 375px;
  margin-inline-start: auto;
  margin-inline-end: auto;
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
}

html[lang='ja'] {
  --font--primary: 'Noto Sans JP', sans-serif;
}

html[lang='th'] {
  --font--primary: 'Kanit', sans-serif;
}

html[lang='vi'] {
  --font--primary: 'Fira Sans', sans-serif;
}

html[lang='ar'] {
  --font--primary: 'Almarai', sans-serif;
}

section {
  font-size: 1.6rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  font-family: var(--font--primary);
}

button {
  border: 0;
}

input {
  border: 0;
}

a {
  border: 0;
  transition: 0.2s;
  text-decoration: none;
  color: var(--black);
}

a:visited,
a:focus,
a:active,
a:hover {
  outline: 0 none;
}

img {
  border: 0;
}

ol,
ul {
  font-family: var(--text-font);
  margin: 0;
  padding: 0;
}

li {
  font-family: var(--text-font);
  list-style-type: none;
}

section,
header,
footer {
  font-size: 1.6rem;
}

svg {
  overflow: visible;
}

input::placeholder,
textarea::placeholder {
  color: var(--color-gray-600);
}

.grecaptcha-badge {
  display: none !important;
}

.tippy-box {
  color: var(--color-neutral);
  background-color: var(--color-gray-300);
  font-size: var(--body-font-size-small);
  line-height: var(--body-line-height-small);
  font-weight: 400;
  box-shadow: 0px 4px 8px 0px #2621190F, 0px 0px 3px 0px #26211929;
  border-radius: 3px;
}

.tippy-content {
  padding: 8px 12px;
}

.tippy-arrow {
  color: var(--color-gray-300);
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.allScrollSides {
  mask: linear-gradient(90deg, #0000, #000 10% 90%, #0000);
}

.initialScrollSides {
  mask: linear-gradient(90deg, #0000, #000 00% 70%, #0000);
}

.endScrollSides {
  mask: linear-gradient(90deg, #0000, #000 20% 100%, #0000);
}

.prevent-rtl {
  direction: ltr;
  unicode-bidi: bidi-override;
}

.slider {
  display: flex;
  justify-content: start !important;
}

@media (max-width: 540px) and (max-height: 926px) {
  iframe#launcher {
    opacity: 0 !important;
  }
}