@font-face {
  font-family: 'Satoshi';
  src: url('./fonts/Satoshi/Satoshi-Regular.woff') format('woff2'),
    url('./fonts/Satoshi/Satoshi-Regular.woff') format('woff');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi';
  src: url('./fonts/Satoshi/Satoshi-Medium.woff2') format('woff2'),
    url('./fonts/Satoshi/Satoshi-Medium.woff') format('woff');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi';
  src: url('./fonts/Satoshi/Satoshi-Bold.woff2') format('woff2'),
    url('./fonts/Satoshi/Satoshi-Bold.woff') format('woff');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Kanit';
  src: url('./fonts/Kanit/Kanit-Regular.woff2') format('woff2'),
    url('./fonts/Kanit/Kanit-Regular.woff') format('woff');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Kanit';
  src: url('./fonts/Kanit/Kanit-Medium.woff2') format('woff2'),
    url('./fonts/Kanit/Kanit-Medium.woff') format('woff');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'Kanit';
  src: url('./fonts/Kanit/Kanit-Bold.woff2') format('woff2'),
    url('./fonts/Kanit/Kanit-Bold.woff') format('woff');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Fira Sans';
  src: url('./fonts/Fira Sans/FiraSans-Regular.woff2') format('woff2'),
    url('./fonts/Fira Sans/FiraSans-Regular.woff') format('woff');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Fira Sans';
  src: url('./fonts/Fira Sans/FiraSans-Medium.woff2') format('woff2'),
    url('./fonts/Fira Sans/FiraSans-Medium.woff') format('woff');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Fira Sans';
  src: url('./fonts/Fira Sans/FiraSans-Bold.woff2') format('woff2'),
    url('./fonts/Fira Sans/FiraSans-Bold.woff') format('woff');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Sans JP';
  src: url('./fonts/Noto Sans JP/NotoSansJP-Regular.woff') format('woff2'),
    url('./fonts/Noto Sans JP/NotoSansJP-Regular.woff') format('woff');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Sans JP';
  src: url('./fonts/Noto Sans JP/NotoSansJP-Regular.woff2') format('woff2'),
    url('./fonts/Noto Sans JP/NotoSansJP-Regular.woff') format('woff');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Sans JP';
  src: url('./fonts/Noto Sans JP/NotoSansJP-Regular.woff2') format('woff2'),
    url('./fonts/Noto Sans JP/NotoSansJP-Regular.woff') format('woff');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Almarai';
  src: url('./fonts/Almarai/Almarai-Regular.woff') format('woff2'),
    url('./fonts/Almarai/Almarai-Regular.woff') format('woff');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Almarai';
  src: url('./fonts/Almarai/Almarai-Bold.woff2') format('woff2'),
    url('./fonts/Almarai/Almarai-Bold.woff') format('woff');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Almarai';
  src: url('./fonts/Almarai/Almarai-Bold.woff2') format('woff2'),
    url('./fonts/Almarai/Almarai-Bold.woff') format('woff');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}