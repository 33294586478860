@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .text-s-medium {
    font-size: var(--body-font-size-small);
    line-height: var(--body-line-height-small);
    color: var(--color-gray-700);
  }

  .text-medium {
    font-size: var(--body-font-size-regular);
    line-height: var(--body-line-height-regular);
    color: var(--color-gray-700);
  }
}